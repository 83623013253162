export interface IPlanSelectedState {
  index: number | string
  name: string
}

export const nameState = 'planSelected'

export const initialState: IPlanSelectedState = {
  index: 0,
  name: '',
}
