import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IModuleBackend } from '@resources/types/services/module'
import { initialState, nameState } from './types'

const moduleBackendSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    applyModuleBackend(state, action: PayloadAction<IModuleBackend>) {
      return {
        ...state,
        backend: { ...action.payload },
      }
    },

    deleteModuleBackend() {
      return initialState
    },
  },
})

export const { applyModuleBackend, deleteModuleBackend } =
  moduleBackendSlice.actions

export default moduleBackendSlice.reducer
