import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialState, nameState } from './types'

const isActive2FAUser = createSlice({
  name: nameState,
  initialState: initialState,
  reducers: {
    toggleIActive2FAU: (state) => {
      state.isActive2FAUser = !state.isActive2FAUser
    },
    setisActive2FAU: (state, action: PayloadAction<any>) => {
      state.isActive2FAUser = action.payload
    },
  },
})

export const { setisActive2FAU, toggleIActive2FAU } = isActive2FAUser.actions

export default isActive2FAUser.reducer
