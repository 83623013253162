import { IContributionHistoryResponse } from '@resources/types/services/contribuition'

export const nameState = 'contribuition'

export interface IContributionHistoryState
  extends IContributionHistoryResponse {
  loanSimulationLoading: boolean
  loanSimulationError?: Error
  loanSimulationSuccess?: boolean
}

export const initialState: IContributionHistoryState = {
  year: '',
  content: [
    {
      date: '',
      userContribution: '',
      companyContribution: '',
      monthly: {
        valueContribution: '',
        eventContribution: '',
        typeContribution: '',
      },
    },
  ],

  loanSimulationLoading: false,
  loanSimulationError: undefined,
  loanSimulationSuccess: undefined,
}
