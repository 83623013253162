import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialState, nameState } from './types'

const loanTermsSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    fetchLoanTermsInit(state) {
      return {
        ...state,
        loanTermsLoading: true,
        loanTermsError: undefined,
        loanTermsSuccess: undefined,
        loanTermsFail: undefined,
      }
    },

    fetchLoanTermsSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
        loanTermsLoading: false,
        loanTermsError: undefined,
        loanTermsSuccess: true,
        loanTermsFail: false,
      }
    },

    fetchLoanTermsFail(state) {
      return {
        ...state,
        loanTermsLoading: false,
        loanTermsError: undefined,
        loanTermsSuccess: false,
        loanTermsFail: true,
      }
    },

    fetchLoanTermsError(state, action: PayloadAction<any>) {
      return {
        ...state,
        loanTermsLoading: false,
        loanTermsError: action.payload,
        loanTermsSuccess: false,
        loanTermsFail: false,
      }
    },

    setLoanTerms(state) {
      return state
    },

    deleteLoanTerms() {
      return initialState
    },
  },
})

export const {
  fetchLoanTermsInit,
  fetchLoanTermsSuccess,
  fetchLoanTermsFail,
  fetchLoanTermsError,
  deleteLoanTerms,
} = loanTermsSlice.actions

export default loanTermsSlice.reducer
