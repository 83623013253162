import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { IModuleTheme } from '@resources/types/services/module'
import { initialState, nameState } from './types'

const moduleThemeSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    applyModuleTheme(state, action: PayloadAction<IModuleTheme>) {
      return {
        ...state,
        theme: { ...action.payload },
      }
    },

    deleteModuleTheme() {
      return initialState
    },
  },
})

export const { applyModuleTheme, deleteModuleTheme } = moduleThemeSlice.actions

export default moduleThemeSlice.reducer
