import { IModuleTheme } from '@resources/types/services/module'

export const nameState = 'moduleTheme'

export interface IModuleThemeState {
  theme: IModuleTheme
}

export const initialState: IModuleThemeState = {
  theme: {} as IModuleTheme,
}
