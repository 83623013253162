import { ILoanDetailsV2Response } from '@resources/types/services/loan'

export const nameState = 'loanV2'

export interface ILoanV2State extends ILoanDetailsV2Response {
  [x: string]: any
  loanLoading: boolean
  loanError?: Error
  loanSuccess?: boolean
}

export const initialState: ILoanV2State = {
  maxAmount: '',
  minAmount: '',
  maxInstallmentsQuantity: 0,
  minInstallmentsQuantity: 0,
  maxInstallmentsAmount: '',
  minInstallmentsAmount: '',
  payrollLoan: '',
  canSimulateLoan: false,
  reasonWhyCantSimulateLoan: '',
  canRequestLoan: false,
  reasonWhyCantRequestLoan: '',
  loanTypes: [],
  account: {
    bank: '',
    branchNumber: '',
    accountNumber: '',
  },
  loans: {
    loanId: '',
    situation: '',
    date: '',
    creditDate: '',
    approvalDate: '',
    hiredValue: '',
    toBePaid: '',
    installmentsValue: '',
    totalInstallments: 0,
    remainingInstallments: 0,
    fees: '',
    loanContract: '',
    monthlyPayments: [],
  },

  loanLoading: true,
  loanError: undefined,
  loanSuccess: undefined,
}
