import { ILoanSimulationResponse } from '@resources/types/services/loan'

export const nameState = 'loanSimulationV3'

export interface ILoanSimulationStateV3 extends ILoanSimulationResponse {
  loanSimulationLoading: boolean
  loanSimulationError?: Error
  loanSimulationSuccess?: boolean
}

export const initialState: ILoanSimulationStateV3 = {
  id: '',
  amount: '',
  installments: [],
  creditDate: '',
  index: {
    description: '',
    value: '',
  },
  totalAmount: '',
  taxes: {
    iof: '',
    totalInterestRate: '',
    monthlyCet: '',
    yearlyCet: '',
    administrationFee: '',
    guaranteeBookingFee: '',
    concessionFee: '',
    annualInterest: '',
    creditedAmount: '',
    totalDiscounts: '',
    renewalValue: '',
    creditAmount: '',
    riskFee: '',
    monthlyInterestRate: '',
  },

  totalInstallments: 0,
  lastInstallmentDate: '',

  loanSimulationLoading: false,
  loanSimulationError: undefined,
  loanSimulationSuccess: undefined,
  indexType: 0,
}
