import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialState, nameState } from './types'

const authSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    fetchAuthInit(state) {
      return {
        ...state,
        signin2Fa: {
          ...state.signin2Fa,
          mfaActive: false,
        },
        signinLoading: true,
        signinError: undefined,
        signinSuccess: undefined,
        signinFail: undefined,
      }
    },

    fetchAuthWith2fa(state, action: PayloadAction<any>) {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          ...action.payload.credentials,
        },
        signin2Fa: {
          ...action.payload.signin2Fa,
        },
        signinLoading: false,
        signinError: undefined,
        signinSuccess: true,
        signinFail: false,
      }
    },

    fetchAuthSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
        credentials: {
          ...state.credentials,
          ...action.payload.credentials,
        },
        signin2Fa: {
          mfaActive: false,
          mfaType: [''],
          email: '',
          phone: '',
        },
        signinLoading: false,
        signinError: undefined,
        signinSuccess: true,
        signinFail: false,
      }
    },

    fetchAuthFail(state) {
      return {
        ...state,
        signin2Fa: {
          mfaActive: false,
          mfaType: [''],
          email: '',
          phone: '',
        },
        signinLoading: false,
        signinError: undefined,
        signinSuccess: false,
        signinFail: true,
      }
    },

    fetchAuthError(state, action: PayloadAction<any>) {
      return {
        ...state,
        credentials: {
          accessToken: '',
          token: '',
          idToken: '',
          refreshToken: '',
          foundationAuthorization: '',
          foundationDocument: '',
        },
        signin2Fa: {
          mfaActive: false,
          mfaType: [''],
          email: '',
          phone: '',
        },
        signinLoading: false,
        signinError: action.payload,
        signinSuccess: false,
        signinFail: true,
      }
    },

    setTokenEnergiaPrev(state, action: PayloadAction<any>) {
      return {
        ...state,
        credentials: {
          ...state.credentials,
          ...action.payload,
          signinLoading: false,
        },
        signin2Fa: {
          mfaActive: false,
          mfaType: [''],
          email: '',
          phone: '',
        },
      }
    },

    deleteAuth() {
      return initialState
    },
  },
})

export const {
  fetchAuthInit,
  fetchAuthWith2fa,
  fetchAuthSuccess,
  fetchAuthFail,
  fetchAuthError,
  setTokenEnergiaPrev,
  deleteAuth,
} = authSlice.actions

export default authSlice.reducer
