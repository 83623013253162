import { ILoanDetailsResponse } from '@resources/types/services/loan'

export const nameState = 'loan'

export interface ILoanState extends ILoanDetailsResponse {
  loanLoading: boolean
  loanError?: Error
  loanSuccess?: boolean
}

export const initialState: ILoanState = {
  maxAmount: '',
  minAmount: '',
  maxInstallmentsQuantity: 0,
  minInstallmentsQuantity: 0,
  maxInstallmentsAmount: '',
  minInstallmentsAmount: '',
  podeSimularEmprestimo: false,
  motivoNaoPoderSimularEmprestimo: '',
  motivoNaoPodeSolicitarEmprestimo: '',
  podeSolicitarEmprestimo: false,
  account: {
    bank: '',
    branchNumber: '',
    accountNumber: '',
  },
  loans: [],
  loanAmountOptions: [],
  loanInstallmentsQuantityOptions: [],
  selectedLoanValueIndex: 0,

  loanLoading: true,
  loanError: undefined,
  loanSuccess: undefined,
}
