import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialState, nameState } from './types'

const contribuitionSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    fetchContribuitionInit(state) {
      return {
        ...state,
        contribuitionLoading: true,
        contribuitionError: undefined,
        contribuitionSuccess: undefined,
      }
    },

    fetchContribuitionSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        contribuitions: action.payload,
        contribuitionLoading: false,
        contribuitionError: undefined,
        contribuitionSuccess: true,
      }
    },

    fetchContribuitionError(state, action: PayloadAction<any>) {
      return {
        ...state,
        contribuitionLoading: false,
        contribuitionError: action.payload,
        contribuitionSuccess: false,
      }
    },
  },
})

export const {
  fetchContribuitionInit,
  fetchContribuitionSuccess,
  fetchContribuitionError,
} = contribuitionSlice.actions

export default contribuitionSlice.reducer
