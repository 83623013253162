export const getFirstWord = (words: string) => {
  if (!words) return null

  const [firstWord] = words.trim().split(' ')

  return firstWord
}

export const getCustomerInitial = (moduleName: string) => {
  const nameSplited = moduleName.split(' ')
  const nameLength = nameSplited.length

  const firstName = nameSplited[0]
  const lastName = nameSplited[nameLength - 1]

  const userInitial = `${firstName[0]}${lastName[0]}`

  return userInitial.toUpperCase()
}

export const translateUrlParam = (
  url: string,
  obj: Record<string, string | number>,
) => {
  let translateUrlParam = url

  Object.keys(obj).forEach((key) => {
    translateUrlParam = translateUrlParam.replace(
      `{{${key}}}`,
      obj[key] as string,
    )
  })

  return translateUrlParam
}

export const translateUrlQuery = (url: string, obj: any) => {
  let translateUrlParam = url

  const filteredObject = Object.fromEntries(
    Object.entries(obj).filter(([, value]) => value !== undefined),
  )

  const keys = Object.keys(filteredObject)

  for (const ob in filteredObject) {
    translateUrlParam =
      ob === keys[0]
        ? `${translateUrlParam}?${ob}=${obj[ob]}`
        : `${translateUrlParam}&${ob}=${obj[ob]}`
  }

  return translateUrlParam
}

export const hidePart = (phoneNumber: string): string => {
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return `(xx) xxxxx-${phoneNumber.slice(7)}`
}

export const breakTextWithHyphen = (text = '') => {
  const hasHyphen = text.includes('-')

  return hasHyphen ? text.split('-')[0] : text
}

export const formatCpfToMask = (cpf: string) => {
  if (!cpf) return

  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
}

export const removeAccents = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const formatStringTaxId = (taxId: string) => {
  if (!taxId) return

  const formattedInput = taxId.replace(/\D/g, '')

  return formattedInput.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
}

export const formatStringCellphone = (cellphone: string) => {
  if (!cellphone) return

  const formattedInput = cellphone.replace(/\D/g, '')

  if (formattedInput.length > 10) {
    return formattedInput.replace(
      /^(\d{2})(\d{1})(\d{4})(\d{4})$/,
      '($1) $2 $3-$4',
    )
  }

  return formattedInput.replace(/^(\d{4})(\d{4})$/, '$1-$2')
}
