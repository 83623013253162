import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { initialState, nameState } from './types'

const userSlice = createSlice({
  name: nameState,
  initialState,
  reducers: {
    fetchUSerInit(state) {
      return {
        ...state,
        userLoading: true,
        userError: undefined,
        userSuccess: undefined,
        userFail: undefined,
      }
    },

    fetchUSerSuccess(state, action: PayloadAction<any>) {
      return {
        ...state,
        ...action.payload,
        userLoading: false,
        userError: undefined,
        userSuccess: true,
        userFail: false,
      }
    },

    fetchUSerFail(state) {
      return {
        ...state,
        userLoading: false,
        userError: undefined,
        userSuccess: false,
        userFail: true,
      }
    },

    fetchUSerError(state, action: PayloadAction<any>) {
      return {
        ...state,
        userLoading: false,
        userError: action.payload,
        userSuccess: false,
        userFail: false,
      }
    },

    setUserPhone(state, action: PayloadAction<any>) {
      return {
        ...state,
        phone: action.payload.phone,
        userLoading: false,
        userError: undefined,
        userSuccess: undefined,
        userFail: undefined,
      }
    },

    setUser(state) {
      return state
    },

    deleteUser() {
      return initialState
    },
  },
})

export const {
  fetchUSerInit,
  fetchUSerSuccess,
  fetchUSerFail,
  fetchUSerError,
  setUserPhone,
  deleteUser,
} = userSlice.actions
export default userSlice.reducer
